import * as R from 'ramda'
import { connect } from 'react-redux'

import * as player from '../player'

import { GamblingHistory as Component } from './gambling-history'

const TWO_WEEKS_IN_MS = 2 * 7 * 24 * 60 * 60 * 1000 // 2 weeks in milliseconds

const GamblingHistory = R.compose(
  connect(state => ({
    language: player.getLanguage(state.player),
    startDate: Math.round((Date.now() - TWO_WEEKS_IN_MS) / 15000) * 15000,
    // Hack to prevent it from re-rendering on every cycle -> Turns into an endless loop
    // TODO: Find a better way of getting todays date.
    endDate: Math.round(Date.now() / 15000) * 15000,
  }))
)(Component)

// For @loadable/components
export default GamblingHistory
