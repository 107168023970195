import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import GamblingHistory from './gambling-history'
import { QueryDrawer } from './query-drawer'
import { useMenuQueries } from './use-menu-queries'

export function GamblingHistoryDrawer() {
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate()

  const { menuQuery } = useMenuQueries()

  return (
    <QueryDrawer
      activeQueryName="gaming-history"
      title={translate('gambling-history.title')}
      onSecondaryAction={() => history.push(`?${menuQuery}`)}
    >
      <GamblingHistory />
    </QueryDrawer>
  )
}

export default GamblingHistoryDrawer

Herz.I18n.Loader.preload(['gambling-history.title'], GamblingHistoryDrawer)
