import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import * as Common from '@rushplay/common'
import * as Compliance from '@rushplay/compliance/gambling-history'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Theming from '../theming'
import * as Icons from '../icons'
import { Amount } from '../amount'
import { Button } from '../button'
import { Divider } from '../divider'
import { HtmlContent } from '../html-content'
import { Spinner } from '../spinner'
import { mapIndexed } from '../map-indexed'

const MOUNT_POINTS = {
  '@rushplay/compliance/gambling-history': ['gamblingHistory'],
  '@rushplay/processes': ['processes'],
}

const Wrapper = styled('div')`
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
`

const IconWrapper = styled.div`
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
`

function HistoryChunk(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      `gambling-history.${props.type}.bet-type`,
      'gambling-history.bet',
      'gambling-history.return',
    ],
    [props.type]
  )

  const [isOpen, setIsOpen] = React.useState(false)

  function handleToggleIsOpen() {
    setIsOpen(curr => !curr)
  }

  return (
    <Theming.Primary>
      <motion.div
        whileHover={{
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          transition: 'ease-in 0.2s',
        }}
      >
        <Common.Box>
          <Common.Box
            display="flex"
            p={1}
            justifyContent="space-between"
            fontSize={2}
            onClick={handleToggleIsOpen}
            backgroundColor={isOpen ? '#2A3149' : 'transparent'}
          >
            <Common.Flex flexDirection="column" flex="1">
              <Common.Box py="4px" fontWeight="bold">
                {props.gameTitle ? props.gameTitle : '-'}
              </Common.Box>
              {!R.equals('unknown', props.type) && (
                <Common.Text color="darken-gray">
                  {props.provider ? props.provider : '-'}
                </Common.Text>
              )}
            </Common.Flex>

            <Common.Flex
              alignItems="center"
              justifyContent="space-between"
              flex={[null, null, null, '1']}
            >
              <Common.Flex flexDirection="column" alignItems="center">
                <Common.Box fontWeight="bold" fontSize={1} pb="4px">
                  <Common.Text>
                    {Common.toLocaleDate(props.date, props.language).date}
                  </Common.Text>
                </Common.Box>
                <Common.Text>
                  {Common.toLocaleDate(props.date, props.language).time}
                </Common.Text>
              </Common.Flex>
              <Common.Box ml={1}>
                <IconWrapper isOpen={isOpen}>
                  <Icons.Chevron />
                </IconWrapper>
              </Common.Box>
            </Common.Flex>
          </Common.Box>
          {isOpen ? (
            <Common.Box width="100%" py={0}>
              <Common.Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                fontSize={1}
              >
                <Common.Box
                  display="flex"
                  mb={0}
                  justifyContent="space-between"
                  width="100%"
                  px="20%"
                >
                  <Common.Box>{translate('gambling-history.bet')}</Common.Box>
                  <Common.Box fontWeight="bold" ml={0}>
                    <Amount currency={props.currency} decimals="show">
                      {props.bet}
                    </Amount>
                  </Common.Box>
                </Common.Box>
                <Common.Box
                  display="flex"
                  mb={0}
                  justifyContent="space-between"
                  width="100%"
                  px="20%"
                >
                  <Common.Box>
                    {translate('gambling-history.return')}
                  </Common.Box>
                  <Common.Box fontWeight="bold" ml={0}>
                    <Amount currency={props.currency} decimals="show">
                      {props.win}
                    </Amount>
                  </Common.Box>
                </Common.Box>
              </Common.Box>
            </Common.Box>
          ) : null}
          <Divider />
        </Common.Box>
      </motion.div>
    </Theming.Primary>
  )
}

HistoryChunk.propTypes = {
  bet: PropTypes.number,
  date: PropTypes.number,
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  language: PropTypes.string,
  type: PropTypes.string,
  gameTitle: PropTypes.string,
  win: PropTypes.number,
  provider: PropTypes.string,
}

export function GamblingHistory(props) {
  const translate = Herz.I18n.useTranslate()
  const transactions = Compliance.useTransactions({
    mountPoints: MOUNT_POINTS,
  })

  React.useEffect(() => {
    transactions.onFetch({ excludeSport: true })
    return () => transactions.onClear()
  }, [transactions.onClear, transactions.onFetch])

  if (transactions.stale && transactions.loading) {
    return (
      <Common.Box
        color="#fff"
        mt={[1, 2]}
        mx={[1, null]}
        py={2}
        px={[1, null, 4]}
        width="100%"
        borderRadius={1}
        boxShadow={[null, null, 0]}
        bg="secondary-background"
      >
        <Spinner />
      </Common.Box>
    )
  }

  function handleFetchMore() {
    return transactions.onFetchMore({ excludeSport: true })
  }

  return (
    <Common.Box
      display="flex"
      width="100%"
      alignItems="center"
      flexDirection="column"
      mb={1}
    >
      <Common.Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py={2}
      >
        <Common.Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          px={1}
          fontSize={2}
        >
          <Common.Text textAlign="center">
            {translate('gambling-history.info-1')}
          </Common.Text>
          <HtmlContent
            html={{ __html: translate('gambling-history.info-2') }}
          />
        </Common.Box>
      </Common.Box>
      <Common.Box
        display="flex"
        flexDirection="column"
        color="#fff"
        mt={[1, 2]}
        mx={[1, null]}
        py={2}
        px={1}
        width="100%"
        maxWidth="700px"
        borderRadius={1}
        boxShadow={[null, null, 0]}
        bg="secondary-background"
      >
        <Wrapper>
          <Common.Space pb={1}>
            <Common.Box display="flex">
              <Common.Box
                color="#fff"
                fontSize={2}
                px={1}
                py={0}
                border="1px solid #919294"
                borderRadius="6px"
              >
                {translate('gambling-history.games')}
              </Common.Box>
            </Common.Box>
          </Common.Space>
        </Wrapper>
        {transactions.empty ? (
          <Common.Box
            px={1}
            py={2}
            width="100%"
            maxWidth="1000px"
            color="menu-item"
            textAlign="center"
          >
            {translate('gambling-history.empty-history')}
            <Common.Space pt={0}>
              <ReactRouter.Link to="/casino">
                <Common.Text textDecoration="underline" fontWeight="800">
                  {translate('gambling-history.make-history')}
                </Common.Text>
              </ReactRouter.Link>
            </Common.Space>
          </Common.Box>
        ) : (
          <React.Fragment>
            <AnimatePresence>
              {mapIndexed((chunk, index) => {
                const total = R.compose(Math.abs, R.sum, R.filter)

                const transactionValues = {
                  bet: total(n => n < 0, [
                    chunk.withdrawAmountCents,
                    chunk.moneyAmountCents,
                  ]),
                  win: total(n => n > 0, [
                    chunk.withdrawAmountCents,
                    chunk.moneyAmountCents,
                  ]),
                }

                return (
                  <motion.div
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{
                      scale: 0.8,
                      opacity: 0,
                      transition: { duration: 0 },
                    }}
                    initial={{ scale: 0.8, opacity: 0 }}
                    key={chunk.createdAt}
                    style={{ width: '100%' }}
                    transition={{
                      duration: 0.25,
                      delay: (index % 10) * 0.04,
                    }}
                  >
                    <HistoryChunk
                      date={chunk.createdAt}
                      language={props.language}
                      currency={chunk.currency}
                      win={transactionValues.win}
                      bet={transactionValues.bet}
                      type={chunk.type}
                      gameTitle={chunk.gameTitle}
                      provider={
                        chunk.subProvider === 'Legendz'
                          ? chunk.subProvider
                          : chunk.provider
                      }
                    />
                  </motion.div>
                )
              }, transactions.data)}
            </AnimatePresence>
            {transactions.onFetchMore && (
              <Wrapper>
                <Common.Box py={1} display="flex" justifyContent="center">
                  <Button
                    disabled={transactions.loading}
                    fontSize={1}
                    variant="secondary"
                    onClick={handleFetchMore}
                  >
                    {translate('gambling-history.show-more')}
                  </Button>
                </Common.Box>
              </Wrapper>
            )}
          </React.Fragment>
        )}
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'gambling-history.not-found',
    'gambling-history.title',
    'gambling-history.show-totals',
    'gambling-history.show-more',
    'account.nav.transaction-history',
    'gambling-history.title',
    'gambling-history.info-1',
    'gambling-history.info-2',
    'gambling-history.games',
    'gambling-history.bet',
    'gambling-history.return',
    'gambling-history.empty-history',
    'gambling-history.make-history',
  ],
  GamblingHistory
)

GamblingHistory.propTypes = {
  language: PropTypes.string.isRequired,
}
